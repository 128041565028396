import React from "react";
import Card from "./Card";

export default function Section() {
  return (
    <>
      <div className="w-full">
        <div className="flex w-100 h-40 bg-[#ac9343] my-5 mx-5 rounded-md">
          <div className="m-auto">Gambar</div>
        </div>
      </div>

      <div className="w-full grid grid-cols-3 gap-2 px-5">
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
      </div>
    </>
  );
}
