import React from "react";

export default function Banner() {
  return (
    <>
      <div className="mx-auto py-10 px-5 w-4/5 text-4xl font-bold text-center text-white">
        Selamat datang di Afandi Batik Store !
      </div>
    </>
  );
}
