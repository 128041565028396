import React from "react";

export default function Footer() {
  return (
    <>
      <div className="mt-10 w-full h-40 bg-[#25211e]"></div>
      <div className="w-full h-10 bg-black"></div>
    </>
  );
}
