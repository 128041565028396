import React from "react";
import Banner from "../components/Banner";
import Nav from "../components/Nav";
import Section from "../components/Section";
import Footer from "../components/Footer";

export default function Home() {
  return (
    <div className="bg-[#25211e] w-full">
      <Nav />
      <Banner />
      <Section />
      <Footer />
    </div>
  );
}
