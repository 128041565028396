import React from "react";
import Nav from "../components/Nav";
import Card from "../components/Card";

export default function Product() {
  return (
    <div className="bg-[#25211e] w-full h-screen">
      <Nav />
      <div className="mx-5 grid grid-flow-col-1 gap-2">
        <Card />
        <Card />
        <Card />
      </div>
    </div>
  );
}
