import React from "react";

export default function Card() {
  return (
    <div className="w-100 h-40 bg-[#ac9343] rounded-md">
      <img src="img.com" alt="" />
      Gambar
      <div className="title">Judul</div>
      <div className="price">Harga</div>
      <div className="desc">Deskripsi</div>
      <a href="www.google.com" className="button">
        Tombol whatsapp
      </a>
    </div>
  );
}
