import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Nav() {
  const [isCol, setIsCol] = useState(true);
  const navigation = useNavigate();

  const navHandler = (e) => {
    e.preventDefault();
    setIsCol(!isCol);
  };

  return (
    <div className="w-full">
      <div className="hidden invisible justify-between p-5 md:flex md:visible">
        <ul>
          <li className="ml-2">Brand</li>
        </ul>
        <ul>
          <li className="inline mx-2">Item</li>
          <li className="inline mx-2">Item</li>
          <li className="inline mx-2">Item</li>
          <li className="inline mx-2">Item</li>
        </ul>
      </div>

      <div
        className={`flex justify-between py-5 px-5 duration-500 ease-in-out z-50 md:hidden md:invisible ${
          isCol ? "bg-[#25211e]" : "bg-white"
        }`}
      >
        <ul className="flex">
          <li className="flex my-auto text-xl font-bold">
            <img
              className="my-auto w-6"
              src={process.env.PUBLIC_URL + "/img/logo.png"}
              alt="logo"
            />
            <div className="ml-2 my-auto align-middle text-[#ac9343]">
              Afandi Batik
            </div>
          </li>
        </ul>
        <ul className="flex">
          <button onClick={(e) => navHandler(e)}>
            <div
              className={`h-1 bg-[#ac9343] w-6 my-1 duration-200 ${
                isCol ? "" : "rotate-45 translate-y-1"
              }`}
            ></div>
            <div
              className={`h-1 bg-[#ac9343] w-6 my-1 duration-200 ${
                isCol ? "" : "hidden invisible"
              }`}
            ></div>
            <div
              className={`h-1 bg-[#ac9343] w-6 my-1 duration-200 ${
                isCol ? "" : "-rotate-45 -translate-y-1"
              }`}
            ></div>
          </button>
        </ul>
      </div>

      <div
        className={`flex h-full w-full bg-white fixed duration-300 ease-in-out z-40 md:hidden md:invisible ${
          isCol
            ? "-translate-y-[9999px] invisible none"
            : "translate-y-[0] visible block"
        }`}
      >
        <ul className="mx-auto">
          <li className="text-center my-10">
            <button onClick={() => navigation("/")} className="font-semibold">
              Home
            </button>
          </li>
          <li className="text-center my-10">
            <a href="www.google.com" className="font-semibold">
              Tentang
            </a>
          </li>
          <li className="text-center my-10">
            <button
              onClick={() => navigation("/produk")}
              className="font-semibold"
            >
              Produk
            </button>
          </li>
          <li className="text-center my-10">
            <a href="www.google.com" className="font-semibold">
              Kontak
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
